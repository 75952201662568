import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import * as React from 'react'
import { useEffect } from 'react'
import Button from './../components/button'

const NotFoundPage = () => {
  useEffect(() => {
    document.body.classList.add('page--not-found')

    return () => {
      document.body.classList.remove('page--not-found')
    }
  }, [])

  return (
    <section className="not-found" aria-labelledby="title">
      <img src="/404.gif" alt="cows in field" className="not-found__image" />
      <div className="not-found__container container">
        <h5 id="title">Error 404</h5>
        <h2>Oops the page you're looking for can't be found.</h2>
        <p>Try going back, or return to our home page</p>
        <Button buttonStyle="green" label="Back to home page" href="/" />
      </div>
    </section>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)
